var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table__row" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          staticClass: "table__row-inner",
          class: { "table__row--dec": !_vm.company.isActive }
        },
        [
          _c(
            "div",
            { staticClass: "table__cell" },
            [
              _c(
                "el-popover",
                {
                  attrs: { placement: "bottom-start" },
                  model: {
                    value: _vm.visible,
                    callback: function($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible"
                  }
                },
                [
                  !!_vm.company.isActive
                    ? _c("span", [_vm._v("Deactivate ")])
                    : _c("span", [_vm._v("Activate ")]),
                  _c("span", [_vm._v(_vm._s(_vm.company.name) + "?")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "margin-top": "12px"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "plain" },
                          on: {
                            click: function($event) {
                              _vm.visible = false
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.changeStatus }
                        },
                        [_vm._v("confirm")]
                      )
                    ],
                    1
                  ),
                  _c("el-switch", {
                    attrs: { slot: "reference", value: _vm.company.isActive },
                    slot: "reference"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.company.name))
            ]),
            _c("div", { staticClass: "table__cell-subtitle" }, [
              _vm._v(
                _vm._s(_vm.company.postalCode) +
                  " " +
                  _vm._s(_vm.company.address)
              )
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.company.country))
            ]),
            _c("div", { staticClass: "table__cell-subtitle" }, [
              _vm._v(
                _vm._s(_vm.company.province) + " " + _vm._s(_vm.company.city)
              )
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.company.admin.name))
            ]),
            _c("div", { staticClass: "table__cell-subtitle" }, [
              _vm._v(_vm._s(_vm.company.admin.email))
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.company.phone))
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell-title" }, [
              _vm._v(_vm._s(_vm.company.hst))
            ])
          ]),
          _c("div", { staticClass: "table__cell" }, [
            _c("div", { staticClass: "table__cell--link" }, [
              _c(
                "a",
                { attrs: { href: _vm.company.website, target: "_blank" } },
                [_vm._v(_vm._s(_vm.$dots(_vm.company.website, 20, false)))]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "table__cell table__cell--end" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "Edit" }
                        },
                        [
                          _c("i", { staticClass: "ri-pencil-fill" }),
                          _vm._v(" Edit ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "table__row-card card" }, [
        _c("div", { staticClass: "card__header" }, [
          _c("div", { staticClass: "card__box" }, [
            _c("div", { staticClass: "card__title" }, [
              _vm._v(_vm._s(_vm.company.name))
            ]),
            _c("div", { staticClass: "card__subtitle" }, [
              _vm._v(_vm._s(_vm.company.address))
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "card__box",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c("el-dropdown-item", { staticClass: "item--edit" }, [
                        _vm.company.phone
                          ? _c(
                              "a",
                              {
                                staticClass: "card__link",
                                attrs: { href: "tel:" + _vm.company.phone }
                              },
                              [
                                _c("i", { staticClass: "ri-phone-fill" }),
                                _vm._v("Call ")
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          attrs: { command: "Edit" }
                        },
                        [
                          _c("i", { staticClass: "ri-pencil-fill" }),
                          _vm._v("Edit ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "card__body" }, [
          _c("div", { staticClass: "card__line" }, [
            _c("div", { staticClass: "card__title" }, [
              _vm._v(_vm._s(_vm.company.admin.name))
            ]),
            _c(
              "a",
              {
                staticClass: "card__title",
                attrs: { href: "mailto:" + _vm.company.admin.email }
              },
              [_vm._v(_vm._s(_vm.company.admin.email))]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }