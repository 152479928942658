<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="page-title">SaaS Companies</div>
            <button class="button button--fill" @click="showAddPopup">
              <i class="ri-add-circle-fill"></i>
              <span>Add New Company</span>
            </button>
            <button class="icon-button icon-button--mobile icon-button--blue" @click="showAddPopup">
              <i class="ri-add-circle-fill"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="table__title-wrapper">
          <div class="container">
            <div class="table__title-inner">
              <div class="table__title-item">On/Off</div>
              <div class="table__title-item">Company Name</div>
              <div class="table__title-item">Location</div>
              <div class="table__title-item">Admin</div>
              <div class="table__title-item">Phone number</div>
              <div class="table__title-item">HST number</div>
              <div class="table__title-item">Website</div>
            </div>
          </div>
        </div>

        <div class="table__main">
          <Company v-for="company in companies" :key="company.id" :company="company" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Company from './Company'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Companies',
  components: {Company, InfiniteLoading},
  data: () => ({
    isLoading: false
  }),
  computed: {
    companies() {
      return this.$store.getters['companies/companies']
    },
    hasMoreResults() {
      return this.companies.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['companies/pagination'].total
    },
    page() {
      return this.$store.getters['companies/page']
    }
  },
  methods: {
    showAddPopup() {
      const mobile = document.documentElement.clientWidth <= 768
      const sidebarParams = {sidebarName: 'AddCompany'}
      if (!mobile) sidebarParams.sidebarStyles = 'width: 700px'
      this.$root.$emit('ShowSidebar', sidebarParams)
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('companies/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
