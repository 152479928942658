<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner" :class="{'table__row--dec': !company.isActive}">
        <div class="table__cell">
          <el-popover v-model="visible" placement="bottom-start">
            <span v-if="!!company.isActive">Deactivate </span>
            <span v-else>Activate </span>
            <span>{{ company.name }}?</span>
            <div style="display: flex; justify-content: space-between; margin-top: 12px">
              <el-button size="mini" type="plain" @click="visible = false">cancel</el-button>
              <el-button type="primary" size="mini" @click="changeStatus">confirm</el-button>
            </div>
            <el-switch slot="reference" :value="company.isActive" />
          </el-popover>
        </div>

        <div class="table__cell">
          <div class="table__cell-title">{{ company.name }}</div>
          <div class="table__cell-subtitle">{{ company.postalCode }} {{ company.address }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ company.country }}</div>
          <div class="table__cell-subtitle">{{ company.province }} {{ company.city }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ company.admin.name }}</div>
          <div class="table__cell-subtitle">{{ company.admin.email }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ company.phone }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ company.hst }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell--link">
            <a :href="company.website" target="_blank">{{ $dots(company.website, 20, false) }}</a>
          </div>
        </div>
        <div class="table__cell table__cell--end">
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item class="item--edit" command="Edit">
                <i class="ri-pencil-fill"></i> Edit
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="card__title">{{ company.name }}</div>
            <div class="card__subtitle">{{ company.address }}</div>
          </div>
          <div class="card__box" @click.stop>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit">
                  <a v-if="company.phone" class="card__link" :href="'tel:' + company.phone">
                    <i class="ri-phone-fill"></i>Call
                  </a>
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="Edit">
                  <i class="ri-pencil-fill"></i>Edit
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="card__body">
          <div class="card__line">
            <div class="card__title">{{ company.admin.name }}</div>
            <a :href="'mailto:' + company.admin.email" class="card__title">{{ company.admin.email }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Company',
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    visible: false
  }),
  methods: {
    action(act) {
      if (act === 'Edit') {
        const mobile = document.documentElement.clientWidth <= 768
        const sidebarParams = {sidebarName: 'AddCompany', componentData: this.company}
        if (!mobile) sidebarParams.sidebarStyles = 'width: 700px'
        this.$root.$emit('ShowSidebar', sidebarParams)
      }
    },
    async changeStatus() {
      try {
        if (this.company.isActive) {
          await this.$store.dispatch(`companies/deactivate`, this.company.id)
        } else {
          await this.$store.dispatch(`companies/activate`, this.company.id)
        }
        this.company.isActive = !this.company.isActive
        this.$store.commit(`companies/change`, this.company)
        this.visible = false
      } catch (e) {
        alert(e.response.data.error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
